import { assetUrl, myevUrl } from '../util/helpers';

import Foundation from './foundation';
import debounce from 'lodash/debounce';
import { maybeAddDisclaimer } from './ConditionDisclaimers';
import { pushGTMCustomEvent } from './pushGTMCustomEvent';
import { toggleAutocompleteValuesBasedOnInput } from '../util/jQueryAutocompleteHelpers';

export class BreedHealthConditions {
  static async init(overrideOptions = {}, useCachedBreeds = false) {
    let allBreedLabelsForAutocomplete;
    if (window?.embark_breeds_list?.length > 0 && useCachedBreeds) {
      allBreedLabelsForAutocomplete = window.embark_breeds_list;
    } else {
      const response = await fetch(myevUrl('breeder-breeds-list'));
      const { identifiableBreeds } = await response.json();
      allBreedLabelsForAutocomplete = identifiableBreeds.map((breed) => breed.display_name);
    }

    const instructionsBreedList = document.getElementById('instructions-breed-list');
    const breedAutocompleteInput = jQuery(document.getElementById('breeds-list'));
    const searchButton = document.getElementById('search-breeds-list');
    const clearButton = document.getElementById('clear-breeds-list');
    searchButton &&
      searchButton.addEventListener('click', function () {
        BreedHealthConditions.searchClickHandler
          .bind(window, breedAutocompleteInput)
          .then(() => {
            const breedsListResults = document.getElementById('breeds-list-results');
            breedsListResults.focus();
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Error in searchClickHandler:', error);
          });
      });

    const clearResults = () => {
      breedAutocompleteInput.val('');
      document.getElementById('breeds-list-results').innerHTML = '';
    };

    clearButton && clearButton.addEventListener('click', clearResults);

    const defaultBreedLabelsForAutocomplete =
      typeof window.embark_popular_breed_health_conditions !== 'undefined'
        ? window.embark_popular_breed_health_conditions
        : [];

    breedAutocompleteInput.autocomplete({
      source: defaultBreedLabelsForAutocomplete,
      delay: 0,
      minLength: 0,
      select(event, ui) {
        if (ui.item) {
          BreedHealthConditions.renderBreedConditions(ui.item.value).catch((_error) => {
            // TODO: Notify Sentry once a client side helper is created
          });
        }
      },
      ...overrideOptions,
    });

    breedAutocompleteInput.autocomplete('instance')._renderItem = function (ul, item) {
      var li = jQuery('<li>')
        .attr({
          id: 'item-' + item.label.replace(/\s+/g, '-').toLowerCase(),
          role: 'option',
        })
        .append(jQuery('<div>').text(item.label));

      return li.appendTo(ul);
    };

    breedAutocompleteInput.autocomplete('widget').attr({
      'role': 'listbox',
      'aria-labelledby': 'breeds-list',
    });

    const toggleSources = () =>
      toggleAutocompleteValuesBasedOnInput(
        breedAutocompleteInput,
        allBreedLabelsForAutocomplete,
        defaultBreedLabelsForAutocomplete,
        'Popular Searches'
      );

    breedAutocompleteInput.on('focus', () => {
      toggleSources();
      breedAutocompleteInput.autocomplete('search');
      instructionsBreedList.setAttribute('aria-hidden', false);
    });

    breedAutocompleteInput.on('blur', () => {
      instructionsBreedList.setAttribute('aria-hidden', true);
    });

    breedAutocompleteInput.on('input', debounce(toggleSources, 150));

    breedAutocompleteInput.on('keyup', async function (e) {
      if (e.keyCode === 13 || e.key === 'Enter') {
        await BreedHealthConditions.renderBreedConditions(jQuery(this).val());
      }
    });

    breedAutocompleteInput.on('autocompleteopen', () => {
      let autocompleteMenu = breedAutocompleteInput.autocomplete('widget');

      if (autocompleteMenu.length) {
        const menuId = autocompleteMenu.attr('id');
        breedAutocompleteInput.attr({
          'aria-controls': menuId,
          'aria-expanded': true,
        });
      }
    });

    breedAutocompleteInput.on('autocompleteclose', () => {
      breedAutocompleteInput.removeAttr('aria-controls');
      breedAutocompleteInput.attr('aria-expanded', false);
    });
  }

  static async searchClickHandler(breedAutocompleteInput, event) {
    event.preventDefault();
    await BreedHealthConditions.renderBreedConditions(breedAutocompleteInput.val());
  }

  static async setupViewFor(breed) {
    const breedAutocompleteInput = jQuery('#breeds-list');
    breedAutocompleteInput.val(breed);
    await BreedHealthConditions.renderBreedConditions(breed);
  }

  static async renderBreedConditions(breed) {
    const container = document.getElementById('breeds-list-results');

    if (!container) {
      return;
    }

    const response = await fetch(myevUrl(`bcd/display/${encodeURIComponent(breed)}`));
    const result = await response.json();

    const searchType = 'Breed Health Search';
    const eventProperties = {
      eventCategory: searchType,
      eventAction: breed,
      eventURL: window.location.href,
    };

    if (!result.length) {
      eventProperties.eventAction += ' - no results';
      pushGTMCustomEvent(searchType, eventProperties);
      return BreedHealthConditions.renderNoResults(breed, container);
    }

    pushGTMCustomEvent(searchType, eventProperties);

    const heading = document.createElement('h2');
    heading.classList.add('text-center', 'conditions-for', 'medium-12');
    heading.innerText = `Conditions For ${breed}`;
    container.innerHTML = '';
    BreedHealthConditions.renderDiseases(result, container);
    container.insertBefore(heading, container.firstChild);
  }

  static renderDiseases(diseases, container) {
    const diseasesAccordion = jQuery('<ul class="accordion medium-12 small-12" />');

    diseases.forEach((disease) => {
      const { condition } = disease;
      const item = jQuery('<li class="grid-x disease accordion-item" data-accordion-item/>');
      const heading = jQuery(`
        <a href="javascript:;" class="accordion-title medium-12 grid-x align-justify">
          <h5 class="medium-9 small-10 disease__name" >
            ${condition.disorder_name} ${condition.subdisorder_name}
          </h5>
         </a>
      `);

      const icons = jQuery('<span class="medium-2 small-10 disease__icons"/>');

      if (disease.isRecommendedByEmbark) {
        icons.append(
          jQuery('<img class="embark-logo" src="//front.embarkvet.com/img/icons/small-yellow-dog@2x.png" alt="Embark">')
        );
      }

      if (disease.isRecommendedByOFA) {
        icons.append(jQuery(`<img class="ofa-logo" src="${assetUrl('/img/logo/ofa-logo.png')}" alt="OFA">`));
      }

      heading.append(icons);
      const meta = jQuery('<div class="grid-x disease__meta accordion-content" data-tab-content/>');

      const metaDescription = jQuery(`
         <div class="medium-12">
            <h6 class="text-muted disease__lay-term">
             ${condition.lay_term} (${condition.category})
           </h6>
           <h6 class="text-muted disease__gene">Gene: ${condition.gene}</h6>
           <h6 class="text-muted disease__inheritance-type">
              Inheritance type: ${condition.moi}
           </h6>
        </div>
      `);

      const description = jQuery(
        `<p class="medim-12 disease__description">${condition.consumer_brief_description_required}</p>`
      );
      meta.append(metaDescription, description);

      if (window.embarkConditionDisclaimerStrings) {
        maybeAddDisclaimer(condition, meta, embarkConditionDisclaimerStrings, 'breedList');
      }

      item.append(heading, meta);
      diseasesAccordion.append(item);
    });

    jQuery(container).append(diseasesAccordion);
    new Foundation.Accordion(diseasesAccordion, {
      allowAllClosed: true,
    });
    // if any magellan links were added in JS enable them.
    if (jQuery('.disclaimer--header, .disclaimerDescriptionText').length) {
      jQuery(document).foundation();
    }

    jQuery(container).append(
      jQuery(`<div class="grid-x align-left medium-12 medium-offset-5 logos-description">
    <div class="medium-6 small-10">
        <img class="embark-logo" src="//front.embarkvet.com/img/icons/small-yellow-dog@2x.png" alt="Embark">
        <small>Embark Recommended</small>
    </div>
    <div class="medium-6 small-10">
       <img class="ofa-logo" src="${assetUrl('/img/logo/ofa-logo.png')}" alt="OFA">
        <small>Registerable with the OFA</small>
    </div>

</div>`)
    );
  }

  static renderNoResults(query, container) {
    container.innerHTML = `
      <div role="status">
        <span>No conditions found for ${query}. However, you will still receive results for all health conditions on our
        <a href='https://embarkvet.com/products/dog-health/health-conditions-list/' class="a-underlined">health list</a>.
        Please contact <a target="_blank" href="mailto:breeders@embarkvet.com" class="a-underlined">breeders@embarkvet.com</a> if you have questions.</span>
       </div>
    `;
  }
}

export const moveAutocompleteWidgetResultsUnderInputSection = (instance) => {
  const [ul] = instance.bindings.toArray().filter((e) => {
    if (typeof e.matches === 'undefined') {
      return;
    }

    return e.matches('ul.ui-autocomplete');
  });

  ul.classList.add('breed-list-group__items');
  jQuery('#breeds-list-autocomplete').append(jQuery(ul));
};

export const replaceAutocompleteResultsMarkup = (instance) => {
  instance._renderItem = (ul, item) => {
    return jQuery(
      `<li class='breed-list-group__item-wrapper'>
                   <div class="grid-x align-middle breed-list-group__item">
                      <div class="small-9 large-8">
                          <h3 class="breed-list-group__item-name headline5">${item.label}</h3>
                      </div>
                  </div>
                </li>`
    ).appendTo(ul);
  };
};
